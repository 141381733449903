import React, { useState } from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  BookmarkIcon,
  CalendarIcon,
  ChatAltIcon,
  DocumentDownloadIcon,
  ExternalLinkIcon,
  LocationMarkerIcon,
  UserCircleIcon,
  VideoCameraIcon,
} from "@heroicons/react/outline"
import ShareLinks from "../components/share-links"
import Image from "../components/image"
import Breadcrumbs from "../components/breadcrumbs"
import TimeZoneToggle from "../components/time-zone-toggle"

const SessionTemplate = ({ data }) => {
  const session = data.wpSession
  const {
    type,
    topics,
    sponsoredBy,
    speakerGroups,
    location,
    format,
    externalLink,
    download,
    dateTime,
  } = session.details
  const [displayLocalTimeZone, setDisplayLocalTimeZone] = useState(false)
  const startDateTime = dateTime?.startTime
    ? new Date(dateTime.startTime + " GMT+0200")
    : null
  const endTime = dateTime?.endTime
    ? new Date(dateTime.startDate + " " + dateTime.endTime + " GMT+0200")
    : null
  const timeOptions = { hour: "2-digit", minute: "2-digit" }
  const dateOptions = {
    // dateStyle: "long",
    month: "long",
    day: "numeric",
  }
  const timeZoneOptions = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneName: "short",
  }
  const localStartDate = startDateTime.toLocaleDateString("en-US", dateOptions)
  const localStartTime = startDateTime.toLocaleTimeString("en-GB", timeOptions)
  const localEndTime = endTime?.toLocaleTimeString("en-GB", timeOptions)

  let localTimeZone = startDateTime.toLocaleTimeString("en-GB", timeZoneOptions)
  localTimeZone = localTimeZone.substring(localTimeZone.indexOf(" ") + 1)

  return (
    <Layout uri={session.uri}>
      <Seo
        title={session.seo?.title || session.title}
        description={session.seo?.metaDesc || session.content}
        image={session.seo?.opengraphImage?.localFile?.publicURL}
      />
      <div className="py-4 shadow-spread bg-gray-100">
        <div className="container flex flex-wrap justify-between items-center">
          <Breadcrumbs />
          <TimeZoneToggle
            enabled={displayLocalTimeZone}
            setEnabled={setDisplayLocalTimeZone}
          />
        </div>
      </div>
      <div className="container my-8 flex">
        <div className="flex-shrink-0 flex-grow-0 mr-4 sm:mr-6">
          <ShareLinks uri={session.uri} title={session.title} />
        </div>
        <article className="flex-shrink flex-grow">
          <div className="flex flex-wrap items-center text-lg mb-4 -mx-4 lg:-mx-6">
            {dateTime?.startTime && (
              <div className="flex items-center mx-4 lg:mx-6 my-2">
                <CalendarIcon className="w-5 h-5 mr-2 text-gray-500 flex-shrink-0" />
                {displayLocalTimeZone ? (
                  <span>
                    {localStartDate}
                    {localStartDate && localStartTime ? ", " : ""}{" "}
                    {localStartTime}
                    {localEndTime ? ` - ${localEndTime}` : null} {localTimeZone}
                  </span>
                ) : (
                  <span>
                    {dateTime?.startTime}{" "}
                    {dateTime.endTime ? `- ${dateTime.endTime}` : null} CEST
                  </span>
                )}
              </div>
            )}
            {location?.physicalLocation || location?.onlineLocation ? (
              <>
                <div className="flex items-center mx-4 lg:mx-6 my-2">
                  {format === "Virtual" ? (
                    <VideoCameraIcon className="w-5 h-5 mr-2 text-gray-500 flex-shrink-0" />
                  ) : (
                    <LocationMarkerIcon className="w-5 h-5 mr-2 text-gray-500 flex-shrink-0" />
                  )}
                  {format && parse(format)}{" "}
                  {format && location.physicalLocation ? "-" : ""}{" "}
                  {location.physicalLocation}{" "}
                  {format === "Hybrid" || format === "Virtual"
                    ? "& Online"
                    : null}{" "}
                </div>
                {location?.onlineLocation ? (
                  <>
                    <a
                      href={location?.onlineLocation.url}
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center mx-4 lg:mx-6 my-2 link"
                    >
                      <VideoCameraIcon className="w-5 h-5 mr-2 flex-shrink-0" />

                      {location?.onlineLocation.title || "Connect online"}
                    </a>
                  </>
                ) : null}
              </>
            ) : null}
            {type && (
              <div className="flex items-center mx-4 lg:mx-6 my-2">
                <ChatAltIcon className="w-5 h-5 mr-2 text-gray-500 flex-shrink-0" />
                {type[0].title}
              </div>
            )}
          </div>
          {session.title && (
            <h1 className="h2 max-w-2xl">{parse(session.title)}</h1>
          )}
          {topics && (
            <ul
              className={`flex flex-wrap -mx-3 mt-6 ${
                !sponsoredBy && session.content ? "max-w-4xl" : ""
              }`}
            >
              {topics.map(topic => (
                <li
                  key={topic}
                  className="flex mx-3 my-1 uppercase text-sm tracking-wider items-center"
                >
                  <BookmarkIcon className="w-5 h-5 mr-1 text-gray-500" />
                  {parse(topic.title)}
                </li>
              ))}
            </ul>
          )}

          {session.content ? (
            <div className="parsed-content mt-6 max-w-3xl">
              {parse(session.content)}
            </div>
          ) : null}

          {download || externalLink ? (
            <div>
              {download ? (
                <a
                  href={download.publicUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary mt-6 mr-3"
                >
                  {download.title}{" "}
                  <DocumentDownloadIcon className="w-5 h-5 ml-2" />
                </a>
              ) : null}

              {externalLink ? (
                <a
                  href={externalLink.url}
                  target={externalLink.target}
                  rel="noreferrer"
                  className="btn btn-primary mt-6 mr-3"
                >
                  {externalLink.title}{" "}
                  <ExternalLinkIcon className="w-5 h-5 ml-2" />
                </a>
              ) : null}
            </div>
          ) : null}
          {sponsoredBy ? (
            <div className="mt-6">
              <h2 className="h3 border-b border-gray-300 mb-5 pb-2">
                Sponsor{sponsoredBy.length > 1 ? "s" : ""}
              </h2>
              <ul className="grid gap-x-6 gap-y-6 md:grid-cols-2">
                {sponsoredBy?.map(sponsor => {
                  const image = sponsor?.featuredImage?.node
                  return (
                    <>
                      <li className="">
                        {image ? (
                          <Image
                            image={image}
                            objectFit="contain"
                            objectPosition="top"
                            className="w-auto h-auto max-h-32 mr-2 lg:mr-3"
                          />
                        ) : (
                          <>
                            <div className="">
                              {sponsor.title && (
                                <div className="font-medium">
                                  {parse(sponsor.title)}
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </li>
                    </>
                  )
                })}
              </ul>
            </div>
          ) : null}
          {speakerGroups ? (
            <>
              <ul className="gap-y-8 grid my-12">
                {speakerGroups?.map(speakerGroup => {
                  return (
                    <li>
                      {speakerGroup.title && (
                        <h2 className="h3 border-b border-gray-300 mb-5 pb-2">
                          {speakerGroup.titleDownload ? (
                            <a
                              href={speakerGroup.titleDownload?.publicUrl}
                              target="_blank"
                              rel="noreferrer"
                              className="flex text-blue hover:text-blue-600 transition"
                            >
                              <DocumentDownloadIcon className="w-6 h-6 mr-2" />
                              {parse(speakerGroup.title)}
                            </a>
                          ) : speakerGroup.titleLink ? (
                            <a
                              href={speakerGroup.titleLink}
                              target="_blank"
                              rel="noreferrer"
                              className="flex text-blue hover:text-blue-600 transition"
                            >
                              <ExternalLinkIcon className="w-6 h-6 mr-2" />
                              {speakerGroup.title}
                            </a>
                          ) : (
                            <span>{speakerGroup.title} </span>
                          )}
                        </h2>
                      )}
                      <ul className="grid gap-x-6 gap-y-6 md:grid-cols-2">
                        {speakerGroup?.speakers?.map(speaker => {
                          const image = speaker?.featuredImage?.node
                          return (
                            <>
                              <li className="flex items-center">
                                {image ? (
                                  <Image
                                    image={image}
                                    objectFit="cover"
                                    objectPosition="top"
                                    className="w-20 h-20 bg-gray-200 rounded-full flex-shrink-0 mr-2 lg:mr-3"
                                    imgClassName="rounded-full"
                                  />
                                ) : (
                                  <UserCircleIcon className="w-20 h-20 bg-gray-200 rounded-full p-4 text-gray-400 flex-shrink-0 mr-2 lg:mr-3" />
                                )}
                                <div className="flex-shrink">
                                  {speaker.title && (
                                    <div className="font-medium">
                                      {parse(speaker.title)}
                                    </div>
                                  )}
                                  {speaker.speaker.titleOrganizationCountry && (
                                    <div className="text-gray-600">
                                      {parse(
                                        speaker.speaker.titleOrganizationCountry
                                      )}
                                    </div>
                                  )}
                                  {speaker.speaker.attendance &&
                                    speaker.speaker.attendance !== "Hidden" &&
                                    speaker.speaker.attendance !== "hidden" && (
                                      <div className="text-gray-600 uppercase text-xs tracking-wider">
                                        {parse(speaker.speaker.attendance)}
                                      </div>
                                    )}
                                </div>
                              </li>
                            </>
                          )
                        })}
                      </ul>
                    </li>
                  )
                })}
              </ul>
            </>
          ) : null}
        </article>
      </div>
    </Layout>
  )
}

export default SessionTemplate

export const sessionQuery = graphql`
  query WordPressSession($id: String!) {
    wpSession(id: { eq: $id }) {
      title
      id
      uri
      content
      seo {
        title
        metaDesc
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      details: session {
        type {
          ... on WpSessionType {
            title
          }
        }
        topics {
          ... on WpTopic {
            title
          }
        }
        sponsoredBy {
          ... on WpSponsor {
            title
            featuredImage {
              node {
                altText
                localFile {
                  extension
                  childImageSharp {
                    gatsbyImageData(height: 128)
                  }
                  publicURL
                }
              }
            }
          }
        }
        speakerGroups {
          title
          titleLink
          titleDownload {
            publicUrl
          }
          speakers {
            ... on WpSpeaker {
              title
              speaker {
                titleOrganizationCountry
                attendance
              }
              featuredImage {
                node {
                  altText
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(width: 128)
                    }
                    publicURL
                  }
                }
              }
            }
          }
        }
        location {
          onlineLocation {
            title
            url
            target
          }
          physicalLocation
        }
        format
        externalLink {
          title
          url
          target
        }
        download {
          title
          publicUrl
        }
        dateTime {
          startTime
          endTime
          startDate
        }
      }
    }
  }
`
