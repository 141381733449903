/**
 * Share Links component
 **/

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  FaTwitter,
  FaFacebookSquare,
  FaPinterest,
  FaEnvelope,
  FaLinkedin,
} from "react-icons/fa"
import CopyLinkButton from "./copy-link-button"

const ShareLinks = ({ shareLink, uri, title, className, active }) => {
  const data = useStaticQuery(graphql`
    {
      wp {
        generalSettings {
          title
        }
      }
      wpOption(id: { eq: "cG9zdDoxMjg=" }) {
        share {
          links {
            link {
              target
              title
              url
            }
            type
          }
        }
      }
    }
  `)
  const shareLinks = data?.wpOption?.share?.links
  const siteTitle = data?.wp?.generalSettings?.title
  return (
    <ul className="grid grid-cols-1 gap-3">
      <CopyLinkButton />
      {shareLinks?.map(shareLink => {
        return (
          <li key={shareLink.type}>
            <a
              href={`${shareLink.link.url}${uri ? uri : ""}${
                title
                  ? `${
                      shareLink.type === "twitter"
                        ? `&text=${title} - ${siteTitle}`
                        : shareLink.type === "pinterest"
                        ? `&description=${title} - ${siteTitle}`
                        : shareLink.type === "email"
                        ? `&subject=${title} - ${siteTitle}`
                        : ""
                    }`
                  : ""
              }`}
              target={shareLink.link.target}
              className={`btn btn-primary round inline-flex text-xl p-2 text-white hover:text-white hover:shadow-lg ${className} ${
                active ? "outline-black" : ""
              }`}
              aria-label={`Share to ${shareLink.type}`}
            >
              {shareLink.type === "twitter" ? (
                <FaTwitter className="w-4 h-4" />
              ) : shareLink.type === "facebook" ? (
                <FaFacebookSquare className="w-4 h-4" />
              ) : shareLink.type === "pinterest" ? (
                <FaPinterest className="w-4 h-4" />
              ) : shareLink.type === "linkedin" ? (
                <FaLinkedin className="w-4 h-4" />
              ) : shareLink.type === "email" ? (
                <FaEnvelope className="w-4 h-4" />
              ) : null}
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export default ShareLinks
