import React from "react";
import { useLayer } from "react-laag";
import { motion, AnimatePresence } from "framer-motion";
import { kebabCase } from "lodash";
import { FaLink, FaCopy } from "react-icons/fa";

function CopyLinkButton(props) {
  const [isOpen, setOpen] = React.useState(false);

  // helper function to close the menu
  function close() {
    setOpen(false);
  }

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    onOutsideClick: close, // close the menu when the user clicks outside
    onDisappear: close, // close the menu when the menu gets scrolled out of sight
    overflowContainer: false, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: "top-end", // we prefer to place the menu "top-end"
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
  });

  // Again, we're using framer-motion for the transition effect
  return (
    <li className="">
      <button
        className={`btn btn-primary round`}
        {...triggerProps}
        onClick={() => {
          navigator.clipboard.writeText(
            window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname +
              (props.heading ? "#" + kebabCase(props.heading).replace(/[0-9]/g, '') : "")
          );

          setOpen(!isOpen);
        }}
      >
        <FaLink className="w-4 h-4" />
      </button>
      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="tooltip-box bg-gray-700 text-white max-w-xs p-3 rounded-md shadow-lg text-sm z-20"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.1 }}
              {...layerProps}
            >
              <FaCopy className="inline-block mr-1" />
              Link copied!
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </li>
  );
}

export default CopyLinkButton;
